<template>
  <div class="search-jobs">
    <div class="filter-box">
      <div class="filter-jobs user-content">
        <!-- <div class="keyword-search">
          <div class="keyword-input">
            <input
              type="text"
              placeholder="请输入搜索职位"
              v-model="jobSearchForm.workName"
              @keyup.enter="searchJobBykeyWord"
            />
          </div>
          <div class="keyword-submit" @click="searchJobBykeyWord">搜索</div>
        </div> -->
        <!-- <div class="hot-search-box">
          <h3 class="hot-search-title">热门搜索</h3>
          <div class="hot-search-tag-box">
            <div
              class="hot-search-tag-item"
              v-for="(item, index) in hotSearchList"
              :key="index"
              @click="hotKeySearch(item.title)"
            >
              <el-button class="hot-search-tag-button" size="mini" type="danger">
                {{ item.title }}
              </el-button>
            </div>
          </div>
        </div> -->
        <div class="filter-form">
          <!-- <div class="form-item flex">
            <div class="item-title">工作地点:</div>
            <Changecity @getList="handleAreaChange"></Changecity>
          </div> -->
          <div class="form-item flex">
            <div class="item-title">职位分类:</div>
            <div class="item-desc">
              <el-cascader
                v-model="jobSearchForm.industryId"
                :options="allIndustrList"
                size="mini"
                :props="jobProps"
                :clearable="true"
                @change="handleCascaderChange"

              >
              </el-cascader>
            </div>
          </div>
          <div class="form-item flex">
            <div class="item-title">工作经验:</div>
            <div class="item-desc">
              <span
                v-for="(item, index) in workList"
                :key="`workList${index}`"
                :class="{ cur: workIndex === index }"
                @click="handleOtherClick(index, item, 'workIndex')"
                style="height:24px;"
                >{{ item }}</span
              >
            </div>
          </div>
          <div class="form-item flex">
            <div class="item-title">学历要求:</div>
            <div class="item-desc">
              <span
                v-for="(item, index) in eduList"
                :key="`eduList${index}`"
                :class="{ cur: eduIndex === index }"
                @click="handleOtherClick(index, item, 'eduIndex')"
                style="height:24px;"
                >{{ item }}</span
              >
            </div>
          </div>

          <div class="form-item flex">
            <div class="item-title">月薪范围:</div>
            <div class="item-desc">
              <span
                v-for="(item, index) in salesList"
                :key="`salesList${index}`"
                :class="{ cur: salesIndex === index }"
                @click="handleOtherClick(index, item, 'salesIndex')"
                style="height:24px;"
                >{{ item }}</span
              >
            </div>
          </div>
          <div class="form-item flex">
              <div class="item-title">职位类型:</div>
              <div class="item-desc">
                  <span
                  v-for="(item, index) in typeList"
                  :key="`typeList${index}`"
                  :class="{ cur: typeIndex === index }"
                  @click="handleOtherClick(index, item, 'typeIndex')"
                  style="height:24px;"
                  >{{ item.title }}</span
                >
              </div>
          </div>

        </div>
      </div>
    </div>
    <div class="job-box user-content flex" >
      <div class="job-list" v-loading="loading">
        <div class="job-list-c" v-if="jobList.length > 0">
          <div
            class="job-item"
            v-for="(item, index) in jobList"
            :key="`jobList${index}`"

          >
            <div class="info-primary flex">
              <div class="job-message">
                <div>
                  <div class="job-name flex" @click="routeToJobDetail(item)">
                  <div class="name-title" >{{ item.workName }}</div>
                  <div class="address">{{ item.workAreaDetail }}</div>
                </div>
                <div class="job-limt flex">
                  <div class="sales">{{ item.salaryName }}</div>
                  <div class="years">{{ item.workYears }}</div>
                  <div class="vline"></div>
                  <div class="edu">{{ item.workEducation }}</div>
                  <div class="vline"></div>
                  <div class="edu">{{ item.workWelfare }}</div>
                </div>
                </div>


              </div>
              <div class="company-message flex">
                <div class="company-info flex align-center" @click="routeToCompany(item)">
                  <div class="info-name">{{ item.companyName }}</div>
                  <!--                                    <div class="name-desc text-overflow">-->
                  <!--                                        {{item.companyNatureName}}<span class="vline"></span>{{item.register}}万元<span class="vline"></span>10000人以上-->
                  <!--                                    </div>-->
                </div>
                <div class="company-logo">
                  <img
                    :src="
                      item.logo ||
                      require('../../../assets/img/users/company_logo.png')
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="info-append flex">
              <div class="tags">

                <span>更新时间：{{ item.updateTime.split(" ")[0] }}</span>
              </div>
              <div class="append-desc text-overflow text-right">
                <el-button type="primary" plain @click="gotoDating(item)">面试大厅({{ item.joinUserNumber }}/8)</el-button>
              </div>
            </div>
          </div>
          <app-pagination
            @handleCurrentChange="handleCurrentChange"
            :total="total"
          ></app-pagination>
        </div>
        <div class="list-no-data flex" v-else>
          <img src="../../../assets/img/users/noJobTip.png" alt="" />
        </div>
      </div>
      <!--            <div class="avata">12</div>-->
    </div>
  </div>
</template>
<script>
import AppPagination from "../../../components/pagination/AppPagination";
import userRequest from "../../../api/user";
import companyRequest from "../../../api/company";
import Changecity from '@/components/Changecity.vue'
export default {
  components: {
    AppPagination,
    Changecity,
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    let query = this.$route.query;
    query.workName && (this.jobSearchForm.workName = query.workName);
    query.workType && (this.$set(this.jobSearchForm,"workType",query.workType*1));
    query.workType && (this.typeIndex=query.workType*1);


    console.log(this.jobSearchForm)
    this.getAllIndustry();
    this.handleProvinceChange(17);
    this.handlecityChange(240);
    this.getJobList();
  },
  data() {
    return {
      total: 0,
      loading: true, // 是否在加载职位的列表 默认是没加载的
      jobList: [],
      isEmpty: false, // 当前的列表是否为空
      allIndustrList: [], //行业分类
      jobSearchForm: {
        workName: "", //职位名
        pageSize: 10,
        pageNum: 1,
        industryId: "", //职位分类
        workYears: "",
        workEducation: "",
        wagesId: "",
        workType:null,//职位类型
      },
      salesList: [
        "不限",
        "1000-2000元",
        "2000-3000元",
        "3000-5000元",
        "5000-8000元",
        "8000-12000元",
        "12000-15000元",
        "15000-20000元",
        "20000元以上",
      ],
      salesIndex: 0,
      eduList: [
        "不限",
        "初中及以下",
        "高中",
        "中专/中技",
        "大专",
        "本科",
        "硕士",
        "MBA/EMBA",
        "博士",
      ],
      eduIndex: 0,
      workList: [
        "不限",
        "1年以下",
        "1-2年",
        "3-5年",
        "6-7年",
        "8-10年",
        "10年以上",
      ],
      workIndex: 0,
      typeIndex:0,
      typeList:[
        {
          title:"不限",
          value:null
        },
        {
          title:"全职",
          value:1
        },
        {
          title:"灵活用工岗位",
          value:2
        },
        // {
        //   title:"全职/灵活用工岗位",
        //   value:3
        // },
        {
          title:"实习",
          value:4
        },
        {
          title:"共享用工",
          value:5
        },
      ],
      provinceList: [], //省列表
      cityList: [], //市列表
      areaList: [], //区域列表
      province: "江西省", //选中省
      city: "广信区", //选中市
      area: "", //区域
      areaId: "", // 区域的id
      jobProps: {
        //级联
        expandTrigger: "hover",
        label: "name",
        value: "id",
        children: "children",
      },
      hotSearchList: [
        {
          title: "普工",
        },
        {
          title: "电工",
        },
        {
          title: "服务员",
        },
        {
          title: "保安",
        },
        {
          title: "保姆",
        },
        {
          title: "司机",
        },
        {
          title: "保洁",
        },
        {
          title: "营业员",
        },
      ],
    };
  },
  methods: {
    //获取行业分类
    getAllIndustry() {
      userRequest
        .getAllIndustry()
        .then((res) => {

          if (res.code === 200) {
            this.allIndustrList = res.data;
            setTimeout(() => {
              this.categoryStatus = false;
            }, 500);
          }
        })
        .catch(() => {
          this.categoryStatus = false;
        });
    },
    hotKeySearch(name) {
      this.jobSearchForm.workName = name;
      this.searchAsFirst();
    },
    handleOtherClick(index, row, flag) {
      this[flag] = index;
      if (flag === "workIndex") {
        index === 0
          ? (this.jobSearchForm.workYears = "")
          : (this.jobSearchForm.workYears = row);
      } else if (flag === "salesIndex") {
        index === 0
          ? (this.jobSearchForm.wagesId = "")
          : (this.jobSearchForm.wagesId = index);
        // this.jobSearchForm.wagesId = index
      } else if (flag === "eduIndex") {
        index === 0
          ? (this.jobSearchForm.workEducation = "")
          : (this.jobSearchForm.workEducation = row);
        // this.jobSearchForm.workEducation = row
      }else if (flag === "typeIndex") {
        this.jobSearchForm.workType=this.typeList[index].value
        // this.jobSearchForm.workEducation = row
      }
      this.searchAsFirst();
    },
    handleCurrentChange(num) {
      document.documentElement.scrollTop=0

      this.jobSearchForm.pageNum = num;
      this.getJobList();
    },
    routeToJobDetail(item) {

     const {href} = this.$router.resolve({
        path: '/user/jobDetail',
        query: {
          workId: item.id,
          workName: item.workName,
        }
      })
      window.open(href, '_blank')

      // this.$router.push({
      //   path: "/user/jobDetail",
      //   query: {
      //     workId: item.id,
      //     workName: item.workName,
      //   },
      // });


    },
    gotoDating(item){
      const {href} = this.$router.resolve({
        path: '/user/tempInterview',
        query: {
          workId: item.id,
        }
      })
      window.open(href, '_blank')

    },
    routeToCompany(item){
      const {href} = this.$router.resolve({
        path: '/user/companyNews/gongsi',
        query: {
          companyId: item.memberId,
        }
      })
      window.open(href, '_blank')
    },
    getCityList(location, id) {
      // console.log(location,id)
      companyRequest.getCityList({ id: id }).then((res) => {
        if (res.code === 200) {
          // console.log(res)
          this[location] = res.data;
        }
      });
    },
    async handleProvinceChange(val) {
      // console.log("选择的省",val)
      this.province = "江西省";
      await this.getCityList("cityList", val);
      // this.areaList = []
      // this.area = ''
      // this.searchAsFirst()
    },
    async handlecityChange(val) {
      // console.log("选择的市",val)
      this.city = "广信区";
      await this.getCityList("areaList", val);
      // this.area = ''
      // this.searchAsFirst()
    },
    handleAreaChange(val) {
      // console.log("选择的区域",val)
      this.areaId = val;
      this.searchAsFirst();
    },
    searchJobBykeyWord() {
      this.searchAsFirst();
    },
    handleCascaderChange() {
      this.searchAsFirst();
    },
    searchAsFirst() {
      this.jobSearchForm.pageNum = 1;
      this.jobList = [];
      this.getJobList();
    },
    searchAsFirst2(data) {
      this.area=data
      this.jobSearchForm.pageNum = 1;
      this.jobList = [];
      this.getJobList();
    },
    /**
     * 搜索职位
     */
    getJobList() {
      this.loading = true;
      let obj = JSON.parse(JSON.stringify(this.jobSearchForm));
      if (obj.industryId.length > 0) {
        // 行业id
        obj.industryId = obj.industryId.join(",");
      } else {
        obj.industryId = "";
      }
      // obj.workArea = `${this.province},${this.city}`  // 工作地区: 默认江西省上饶
      // if (this.area) {
      //     obj.workArea = `${this.province},${this.city},${this.area}`
      // }

      obj.workArea = this.areaId;

      /*
            * else if(this.city){
                obj.workArea = `${this.province},${this.city}`
            } else if (this.province) {
                obj.workArea = `${this.province}`
            } else {
                obj.workArea = ''
            }
            * */

      userRequest
        .getJobList(obj)
        .then((res) => {
          if (res.code === 200) {
            this.jobList = res.data.list;
            this.total = res.data.total;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.jobList = [];
          this.total = 0;
        });
    },
    handleCurrent() {},
  },
  watch: {
    $route(){
      this.jobSearchForm.workName= this.$route.query.workName
    },
    'jobSearchForm.workName':{
      deep:true,
        handler: function(newV, oldV) {
          console.log(newV);
          this.getJobList();
        }

      }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/global.scss";
.search-jobs {
  height: 100%;
  /*margin-top: 80px;*/
  .filter-box {
    padding: 20px 0;
    background: #fff;
    /*position: relative;*/
    z-index: 999;
    box-shadow: 0 3px 6px rgba(6, 0, 1, 0.05);
    // position: sticky;
    // top: 72px;
    .filter-jobs {
      .keyword-search {
        height: 46px;
        margin-bottom: 20px;
        .keyword-input {
          float: left;
          box-sizing: border-box;
          padding: 0 15px;
          width: 791px;
          height: 46px;
          line-height: 20px;
          border-color: #fff;
          border: 1px solid #e8e8e8;
          border-right: 0;
          input {
            display: block;
            width: 100%;
            height: 40px;
            border: none;
            outline: none;
            font-size: 14px;
            &::placeholder {
              color: #bbb;
              font-size: 14px;
            }
          }
        }
        .keyword-submit {
          cursor: pointer;
          float: left;
          padding: 16px 55px;
          background-color: $main;
          color: #fff;
          border: 0;
          &:hover {
            background: $hover;
          }
        }
      }
      .filter-form {
        .form-item {
          padding: 5px 0;
          font-size: 14px;
          align-items: center;
          .item-title {
            margin-right: 15px;
            width: 65px;
            text-align: right;
            color: #555;
            font-weight: 600;
          }
          .item-desc {
            flex: 1;
            span {
              cursor: pointer;
              display: inline-block;
              margin-right: 5px;
              padding: 5px 8px;
              height: 14px;
              line-height: 14px;
              &.cur {
                background: $main;
                color: #fff;
              }
              &:hover {
                background: $main;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .job-box {
    margin-top: 30px;
    .job-list {
      width: 100%;
      min-height: 600px;
      .job-item {
        box-sizing: border-box;
        width: 100%;
        cursor: pointer;
        // padding: 0 30px;
        height: 130px;
        background: #fff;
        margin-bottom: 20px;
        .info-primary {
          padding: 0 30px;
          padding-top: 20px;
          height: 86px;
          color: #4a4160;
          justify-content: space-between;
          border-bottom: 1px solid #f3f3f3;
          .job-message {
            width: 440px;
            margin-top: 4px;
            .job-name {
              color: $green;
              font-size: 16px;
              margin-bottom: 10px;
              .name-title {
                max-width: 240px;
                margin-right: 30px;
              }
              .address {
                max-width: 180px;
              }
            }
            .job-limt {
              align-items: center;
              .sales {
                font-size: 16px;
                color: $other;
                margin-right: 12px;
              }
              .years {
                color: #61687c;
                font-size: 14px;
              }
              .vline {
                display: inline-block;
                width: 1px;
                height: 12px;
                vertical-align: middle;
                background: #e0e0e0;
                margin: 0 10px;
              }
              .edu {
                color: #61687c;
                font-size: 14px;
              }
            }
          }
          .company-message {
            width: 350px;
            justify-content: space-between;
            .company-info {
              margin-top: 4px;
              .info-name {
                font-size: 16px;
                color: $green;
                margin-bottom: 10px;
              }
              .name-desc {
                color: #61687c;
                width: 270px;
                font-size: 14px;
                .vline {
                  display: inline-block;
                  width: 1px;
                  height: 12px;
                  vertical-align: middle;
                  background: #e0e0e0;
                  margin: 0 10px;
                }
              }
            }
            .company-logo {
              width: 56px;
              height: 56px;
              img {
                width: 54px;
                height: 54px;
                border: 1px solid #f3f5fb;
                border-radius: 9px;
              }
            }
          }
        }
        .info-append {
          background: #ffffff;
          padding: 0 30px;
          justify-content: space-between;
          height: 40px;
          overflow: hidden;
          /*background: #f5f5f5;*/
          line-height: 40px;
          .tags {
            width: 440px;
            overflow: hidden;
            .tag {
              display: inline-block;
              font-size: 12px;
              height: 21px;
              line-height: 21px;
              padding: 0 8px;
              margin-right: 5px;
              color: #61687c;
              border: 1px solid #e5e5e5;
              border-radius: 2px;
            }
          }
          .append-desc {
            width: 350px;
          }
        }
      }
      .list-no-data {
        flex-direction: column;
        align-items: center;
        margin-top: 200px;
      }
    }
    .avata {
      margin-left: 20px;
      width: 294px;
      height: 200px;
      background: #fff;
    }
  }
}

.hot-search-box {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0px;
  background-color: #ffffff;
  /*padding: 20px 10px;*/
  border-radius: 10px;

  .hot-search-title {
    font-size: 20px;
    color: $main;
    margin: 0px 20px;
  }

  .hot-search-tag-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    .hot-search-tag-item {
      margin-right: 20px;

      .hot-search-tag-button {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  * {
    box-sizing: border-box;
  }
  .hot-search-box {
    display: none;
  }
  .filter-form {
    display: none;
  }

  .keyword-input {
    width: 80vw !important;
  }
  .keyword-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw !important;
    height: 46px !important;
    padding: 0px !important;
  }

  .user-content {
    width: 100vw;
    margin: 0 auto;
  }
  .job-list {
    // width: 100vw !important;
    min-height: 600px;
    .job-item {
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      // padding: 0 30px;
      height: 130px;
      background: #fff;
      margin-bottom: 10px;


      .info-primary {
          padding: 0 20px !important;
          padding-top: 20px;
          height: 70px;
          color: #4a4160;
          justify-content: space-between;
          border-bottom: 0px !important;
          display: flex;
            flex-direction: column;
          .job-message {
            width: 440px;
            margin-top: 4px;
            .job-name {
              color: $green;
              font-size: 16px;
              margin-bottom: 10px;
              .name-title {
                max-width: 240px;
                margin-right: 30px;
              }
              .address {
                max-width: 180px;
              }
            }
            .job-limt {
              align-items: center;
              .sales {
                font-size: 16px;
                color: $other;
                margin-right: 12px;
              }
              .years {
                color: #61687c;
                font-size: 14px;
              }
              .vline {
                display: inline-block;
                width: 1px;
                height: 12px;
                vertical-align: middle;
                background: #e0e0e0;
                margin: 0 10px;
              }
              .edu {
                color: #61687c;
                font-size: 14px;
              }
            }
          }
          .company-message {
            width: 350px;
            align-items: flex-end;
            justify-content: space-between;
            .company-info {
              margin-top: 4px;
              .info-name {

                font-size: 16px;
                color: $green;
                margin-bottom: 10px;
              }
              .name-desc {
                color: #61687c;
                width: 270px;
                font-size: 14px;
                .vline {
                  display: inline-block;
                  width: 1px;
                  height: 12px;
                  vertical-align: middle;
                  background: #e0e0e0;
                  margin: 0 10px;
                }
              }
            }
            .company-logo {
              width: 56px;
              height: 56px;
              img {
                width: 54px;
                height: 54px;
                border: 1px solid #f3f5fb;
                border-radius: 9px;
              }
            }
          }
        }
        .info-append {
          display: none;
        }
    }
  }
}
</style>
